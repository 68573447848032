import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class RegisterSVGIconsService {

  private iconsToRegister: Array<{ name: string, path: string }> = [];

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    // add icons to register
    this.registerSideNavBarIcons();
    // iterate each icon
    for (const icon of this.iconsToRegister) {
      this.matIconRegistry.addSvgIcon(
        icon.name,
        this.domSanitizer.bypassSecurityTrustResourceUrl(icon.path)
      );
    }
  }

  ///////
  private registerSideNavBarIcons() {
    this.iconsToRegister.push({name: "userMenu", path: "assets/images/SVG/Header/perfil-flynorth.svg"});
    this.iconsToRegister.push({name: "overflightsMenu", path: "assets/images/SVG/SideBar/drone.svg"});
    this.iconsToRegister.push({name: "inventoryMenu", path: "/assets/images/SVG/SideBar/setting-lines.svg"});
    this.iconsToRegister.push({name: "helpMenu", path: "/assets/images/SVG/SideBar/information.svg"});
    this.iconsToRegister.push({name: 'passRecovery', path: 'assets/images/SVG/Tablas/pass_recovery.svg'});
    this.iconsToRegister.push({name: 'edit@action', path: '/assets/images/SVG/Editar/edit.svg'});
    this.iconsToRegister.push({name: 'map-role@action', path: '/assets/images/SVG/roles/security.svg'});
    this.iconsToRegister.push({name: 'delete@action', path: '/assets/images/SVG/delete/delete.svg'});
    this.iconsToRegister.push({name: 'view@action', path: '/assets/images/SVG/Login/ojo-flynorth.svg'});
    this.iconsToRegister.push({name: 'location@action', path: '/assets/images/SVG/Tablas/location-pointer.svg'});
    this.iconsToRegister.push({name: 'line', path: '/assets/images/SVG/custom/linea-flynorth.svg'});
    this.iconsToRegister.push({name: 'edit', path: '/assets/images/SVG/Editar/Editar_Activida-flynorth.svg'});

  }

}
