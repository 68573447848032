import { catastroEnv } from "./environments.catastro";
import {itaguiEnv} from "./environments.itagui";

const sitePath = window.location.href;

let exportVars = null;

if (sitePath.includes("catastro.smartsoftlabs.com")) {
  exportVars = catastroEnv;
} else if (sitePath.includes("itagui.smartsoftlabs.com")) {
  exportVars = itaguiEnv;
} else if (sitePath.includes("pyp.skgtecnologia.com")) {
  // exportVars = prodEnv;
} else if (sitePath.includes("localhost")) {
  // if need to test other enviroment on localhost, change this var
  exportVars = itaguiEnv;
}
export const environment = exportVars;

export interface EnvironmentUrls {
  production: boolean;
  URLS: {
    // auth service
    authService: string,
    // logic person
    logicPersonService: string,
    // logic overflights
    logicOverflights: string,
    // logic elements
    logicElements: string,
    // logic drones
    logicDrones: string,
    // Notifications
    Notifications: string,

    landPropertyMS: string,
    // geoserver
    geoserver: string,
    mapBoxTemplate: string,
    //
    logoPath: string
  };
  kcClientid: string;
  env: string;
}
