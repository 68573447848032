import { Component } from '@angular/core';
import { RegisterSVGIconsService } from './shared/services/register-svg-icons.service';
import {LoadingService} from "./services/loading.service";
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public title = 'front-fly-north';

  constructor(
    private registerSVGIconsService: RegisterSVGIconsService,
    public loadingService: LoadingService
  ) { }

}
