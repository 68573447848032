import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, EMPTY } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { from } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TokenService implements HttpInterceptor {

  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  /**
   * intercept interceptor to refresh token
   * @param req request to send
   * @param next next request step
   */
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //console.log('paso por el interceptor', req);

    const skipIntercept = req.headers.has('skip');

    if (skipIntercept) {
      req = req.clone({
        headers: req.headers.delete('skip')
      });
      return next.handle(req);
    }

    return from(this.handle(req, next));

  }

  /**
   * handle validate refresh token and clone request with new access token
   * @param req request to send
   * @param next next request step
   */
  public async handle(req: HttpRequest<any>, next: HttpHandler) {
    try {
      let success = false;
      if (this.authService.checkAccessToken()) {
        const headers = req.headers.append('Authorization', `Bearer ${this.authService.getAccessToken()}`);
        req = req.clone({

          headers
        });
        success = true;
      } else if (this.authService.checkRefreshToken()) {
        const result: any = await this.authService.refreshToken();
        if (result.status === "success") {
          const headers = req.headers.append('Authorization', `Bearer ${this.authService.getAccessToken()}`);
          req = req.clone({
            headers
          });
          success = true;
        }

      }
      if (success) {
        return next.handle(req).toPromise();
      } else {
        this.error();
      }
    } catch (error) {
      this.error();
    }
  }

  /**
   * error redirect to login and delete existing tokens
   */
  public error() {
    this.authService.delAccessToken();
    this.authService.delRefreshToken();
    this.router.navigateByUrl('/login');
    return EMPTY;
  }
}


