import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private router: Router
  ) { }
  public canActivate(route: ActivatedRouteSnapshot) {
    const { login } = route.data;
    if (login) {
      if (!this.authService.checkRefreshToken()) {
        return true;
      }
      this.router.navigateByUrl('/home/monitoring');
      return false;

    } else {
      if (this.authService.checkRefreshToken()) {
        return true;
      }
      this.router.navigateByUrl('/login');
      return false;
    }
  }

}
