import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatTableModule } from "@angular/material/table";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSortModule } from "@angular/material/sort";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSelectModule } from "@angular/material/select";
import { MatMenuModule } from "@angular/material/menu";
import { MatInputModule } from "@angular/material/input";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule, MatPseudoCheckboxModule, MatRippleModule } from '@angular/material/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TextFieldModule } from '@angular/cdk/text-field';
import { MatDialogModule } from "@angular/material/dialog";
import { MatStepperModule } from '@angular/material/stepper';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatRadioModule } from "@angular/material/radio";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatDividerModule } from "@angular/material/divider";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatCardModule } from "@angular/material/card";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatMomentDateModule } from "@angular/material-moment-adapter";

const materialList = [
  MatButtonToggleModule,
  MatStepperModule,
  MatTableModule,
  MatPaginatorModule,
  MatTableModule,
  MatSortModule,
  MatFormFieldModule,
  MatInputModule,
  MatProgressSpinnerModule,
  MatButtonModule,
  MatIconModule,
  MatSlideToggleModule,
  MatSelectModule,
  MatPseudoCheckboxModule,
  MatCheckboxModule,
  MatMenuModule,
  MatDatepickerModule,
  MatNativeDateModule,
  TextFieldModule,
  MatDialogModule,
  MatRadioModule,
  MatTooltipModule,
  MatDividerModule,
  MatExpansionModule,
  MatCardModule,
  MatSlideToggleModule,
  MatAutocompleteModule,
  DragDropModule,
  MatMomentDateModule,
  MatRippleModule,
];

@NgModule({
  declarations: [],
  imports: [CommonModule, materialList],
  exports: [materialList],
  providers: []
})
export class MaterialModule {
}
