import {EnvironmentUrls} from "./environment";

const baseURL = 'https://itagui.smartsoftlabs.com';

export const itaguiEnv: EnvironmentUrls = {
  production: true,
  URLS: {

    // global auth
    authService: baseURL + ':3010/auth-ms/api/v1',

    // logic person
    logicPersonService: baseURL + ':3010/people-ms/api/v1',

    // logic overflights
    logicOverflights: baseURL + ':3010/overflights-ms/api/v1',

    // logic elements
    logicElements: baseURL + ':3010/elements-ms/api/v1',

    // logic drones
    logicDrones: baseURL + ':3010/drones-ms/api/v1',


    // Notifications
    Notifications: baseURL + ':3000',

    // land property MS,

    landPropertyMS: 'https://itagui.smartsoftlabs.com:3010/land-ms/api/v1' ,

    // geoserver
    geoserver: "https://geoserver.smartsoftlabs.com",
    mapBoxTemplate: 'https://api.mapbox.com/styles/v1/ayalaman/ckmmj1q712dma17qsoqixa17e/tiles/256/{z}/{x}' +
      '/{y}@2x?access_token=pk.eyJ1IjoiYXlhbGFtYW4iLCJhIjoiY2tndmltdGhoMDBydTJ3cXQ0Nnd5amU5MCJ9.2y4NkHDinIultBkjk5xSkw',
    logoPath: "assets/catastro/itagui.png"
  },
  kcClientid: "flyprevent-client",
  env: 'DEV'
};
