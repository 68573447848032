import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from "@angular/router";
import { environment } from 'src/environments/environment';
import jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient, public router: Router) { }

  /**
   * login request to authenticate user
   * @param username identication, email or cellphone
   * @param password user password
   * @param rememberme allows remember user credentials
   */
  public async login(username: string, password: string, rememberme: boolean) {
    return new Promise((resolve, reject) => {

      const body = {
        username,
        password,
      };

      const httpOptions = {
        headers: new HttpHeaders({
          'skip': 'true'
        })
      };

      this.http.post<any>(environment.URLS.authService + "/auth/login", body, httpOptions)
        .subscribe((value) => {
          this.saveTokens(value.data.access_token, value.data.refresh_token);
          resolve(value);
        }, (error) => {
          reject(error);
        });
    });
  }

  /**
   * saveTokens save tokens into local storage
   * @param access access token
   * @param refresh refresh token
   */
  public saveTokens(access: string, refresh: string) {
    localStorage.setItem('atoken', access);
    localStorage.setItem('rtoken', refresh);
  }

  /**
   * getAccessToken return access token from local storage
   */
  public getAccessToken() {
    return localStorage.getItem('atoken') ? localStorage.getItem('atoken') : '';
  }

  /**
   * getRefreshToken return refresh token from local storage
   */
  public getRefreshToken() {
    return localStorage.getItem('rtoken') ? localStorage.getItem('rtoken') : '';
  }

  /**
   * delRecoveryToken delete access token from local storage
   */
  public delAccessToken() {
    localStorage.removeItem('atoken');
  }

  /**
   * delRefreshToken delete refresh token from local storage
   */
  public delRefreshToken() {
    localStorage.removeItem('rtoken');
  }

  /**
   * saveRecoveryToken save recovery token into session storage
   * @param token recovery token
   */
  public saveRecoveryToken(token: string) {
    sessionStorage.setItem('token', token);
  }

  /**
   * getRecoveryToken return recovery token from session storage
   */
  public getRecoveryToken() {
    return sessionStorage.getItem('token');
  }

  /**
   * delRecoveryToken delete recovery token from session storage
   */
  public delRecoveryToken() {
    sessionStorage.removeItem('token');
  }

  /**
   * passRecovery send request to password recovery
   * @param type method to identify the user "email" or "id"
   * @param value value of email or id
   * @param via send method "email" or "sms"
   */
  public async passRecovery(type: string, value: string, via: string, docType = "0") {

    const body = {
      recoveryMethod: "otp",
      methodId: type,
      valueId: value,
      sendMethod: via,
      idType: docType
    };

    const httpOptions = {
      headers: new HttpHeaders({
        'skip': 'true'
      })
    };

    return new Promise((resolve, reject) => {
      this.http.post(environment.URLS.authService + "/auth/pass-recovery", body, httpOptions)
        .subscribe((value) => {
          resolve(value);
        }, (error) => {
          console.log(error);
          reject(error);
        });
    });
  }

  /**
   * checkOTP validate otp code
   * @param otp code to validate
   * @param userValue value of email or id
   */
  public async checkOTP(otp: string, userValue: string) {

    const body = new HttpParams()
      .append('userValue', userValue);

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': otp,
        'skip': 'true'
      })
    };

    return new Promise((resolve, reject) => {
      const url = `${environment.URLS.authService}/auth/validate-otp-recovery`;
      this.http.post(url, body.toString(), httpOptions)
        .subscribe(value => resolve(value), (error) => {
          console.log(error);
          reject(error);
        });
    });
  }

  /**
   * changePass change user password
   * @param password new password
   */
  public async changePass(password: string) {

    const body = new HttpParams()
      .append('password', password);

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': this.getRecoveryToken(),
        'skip': 'true'
      })
    };
    console.log(body);
    return new Promise((resolve, reject) => {
      const url = `${environment.URLS.authService}/auth/pass-recovery`;
      this.http.put(url, body.toString(), httpOptions)
        .subscribe(value => resolve(value), (error) => {
          console.log(error);
          reject(error);
        });
    });

  }

  /**
   * getDecodedAccessToken return access token or false
   */
  public getDecodedAccessToken() {
    try {
      const token = this.getAccessToken();
      return jwt_decode(token);
    } catch (error) {
      return false;
    }
  }

  /**
   * getDecodedAccessToken return access token or false
   */
  public getDecodedRefreshToken() {
    try {
      const token = this.getRefreshToken();
      return jwt_decode(token);
    } catch (error) {
      return false;
    }
  }

  /**
   * checkAccessToken validate if access token is valid
   */
  public checkAccessToken(): boolean {
    const token: any = this.getDecodedAccessToken();
    if (token) {
      if (Date.now() >= (token.exp * 1000) - 30000) {
        return false;
      }
      return true;
    }
    return false;
  }

  /**
   * checkRefreshToken validate if refresh token is valid
   */
  public checkRefreshToken(): boolean {
    const token: any = this.getDecodedRefreshToken();
    if (token) {
      if (Date.now() >= token.exp * 1000) {
        return false;
      }
      return true;
    }
    return false;
  }

  /**
   * refreshToken generate new access and refresh token
   */
  public refreshToken() {
    const refreshToken: any = this.getRefreshToken();
    if (refreshToken) {
      return new Promise((resolve, reject) => {

        const body = {
          refresh_token: refreshToken
        };

        const httpOptions = {
          headers: new HttpHeaders({
            'skip': 'true'
          })
        };

        this.http.post<any>(environment.URLS.authService + "/auth/refresh-token", body, httpOptions)
          .subscribe((value) => {
            this.saveTokens(value.data.access_token, value.data.refresh_token);
            resolve(value);
          }, (error) => {
            reject(error);
          });
      });
    }
    return false;
  }

  /**
   * logout sign out and delete keycloak session
   */
  public logout() {
    const token: any = this.getAccessToken();
    if (token) {
      return new Promise((resolve, reject) => {

        const httpOptions = {
          headers: new HttpHeaders({
            'skip': 'true',
            'Authorization': token
          })
        };

        this.http.get<any>(environment.URLS.authService + "/auth/sign-out", httpOptions)
          .subscribe((value) => {
            resolve(value);
          }, (error) => {
            reject(error);
          });
      });
    }
    return false;
  }

  public testRequest() {
    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.URLS.authService + "/anonymous")
        .subscribe((value) => {
          resolve(value);
        }, (error) => {
          reject(error);
        });
    });
  }

}
