import { Injectable } from '@angular/core';

export enum LoadingColors{
  DARKBLUE= "rgb(5, 53, 107)",
  WHITE = "white"
}

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  private _loading = false;
  private _loadingColor = "white";

  private _ngxLoadingAnimationTypes = {
    chasingDots: 'chasing-dots',
    circle: 'sk-circle',
    circleSwish: 'circleSwish',
    cubeGrid: 'sk-cube-grid',
    doubleBounce: 'double-bounce',
    none: 'none',
    pulse: 'pulse',
    rectangleBounce: 'rectangle-bounce',
    rotatingPlane: 'rotating-plane',
    threeBounce: 'three-bounce',
    wanderingCubes: 'wandering-cubes'
  };

  get ngxLoadingAnimationTypes(){
    return this._ngxLoadingAnimationTypes;
  }

  get loading(): boolean{
    return this._loading;
  }

  get loadingColor(): string{
    return this._loadingColor;
  }

  public setLoading(status: boolean, color: LoadingColors = LoadingColors.DARKBLUE){
    this._loading = status;
    this._loadingColor = color;
  }

  constructor() { }
}
